// DraggableList.tsx
import React, { useRef, useState, useEffect } from 'react';
import { useSprings, animated, config } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
//@ts-ignore
import move from 'lodash-move';
import Modal from 'react-modal';
import _ from 'lodash';
import styles from './styles.module.css';
import { FaQuestionCircle, FaCog, FaShare, FaRedo, FaMagic } from 'react-icons/fa';
import ReactGA from 'react-ga4';
//ReactGA.initialize('G-1STP858E3T');

const pixle_numX = 90; //change this to scale the grid
const pixle_numY = 100;
const currentDay = new Date().getUTCDay(); //gets the UTC day of the week for randomization
const shareUrl = window.location.href;

//--------------------------------------------------Change this stuff for the daily words--------------------------------------------------------------------
interface ThemeData { //used to set all properties of the words and themes. 
  theme: string;
  words: string[];
}
const gameData = getThemeData(currentDay); //gets the themes and words based on the UTC day. 
const words = gameData.words; //list of words
const theme = gameData.theme; //theme of the day
const tempLetterArray = generateTempLetterArray(words);

function generateTempLetterArray(words: string[]): string[] { //will take in the list of words and create the seperated array of letters for the grid
  return words.join('').split('');
}
function getThemeData(currentDay: number): ThemeData { //sets all the theme data based on the UTC day. 
  switch (currentDay) {
    case 0: //Sat @ 7EST
      return {theme: 'Zodiac', words: ['SIGN', 'STAR', 'FATE']};
    case 1: //Sun @ 7EST
      return {theme: 'Fears', words: ['DARK', 'GRIM', 'DOOM']};
    case 2: //Mon @ 7EST
      return {theme: 'Games #2', words: ['DICE', 'CARD', 'PLAY']};
    case 3: //Tues @ 7EST
      return {theme: 'Jazz', words: ['BEAT', 'NOTE', 'JIVE']};
    case 4: //Wed @ 7EST
      return {theme: 'Fashion', words: ['GOWN', 'HEEL', 'TIES']};
    case 5: //Thur @ 7EST
      return {theme: 'Health', words: ['DIET', 'YOGA', 'WELL']};
    case 6: //Fri @ 7EST
      return {theme: 'Cats', words: ['PAWS', 'TAIL', 'MEOW']};
    default: //Default, which is useless
      return {theme: 'Camping', words: ['FIRE', 'TENT', 'SITE']};
  }
}
//----------------------------------------------------------------------------------------------------------------------------------------------------------
export default function App() {

  const [darkMode, setDarkMode] = useState(true);
  
  // Toggle dark mode function
  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
    document.documentElement.style.setProperty('--border-color', darkMode ? 'black' : 'white');
    document.documentElement.style.setProperty('--background-color', darkMode ? 'black' : 'white');
    document.documentElement.style.setProperty('--icon-color', darkMode ? 'white' : 'black');
    document.documentElement.style.setProperty('--unused-background-color', darkMode ? 'black' : 'white'); // Update unused background color
    document.body.style.backgroundColor = darkMode ? 'white' : 'black';
  };

  // Set body background color based on browser setting when the component mounts
  useEffect(() => {
    // Check if the user prefers dark mode
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    // Use the user's preference if available, otherwise use your default (true for dark mode, false for light mode)
    const initialDarkMode = prefersDarkMode || false; // Change this to false if you want to start in light mode
    setDarkMode((prevDarkMode) => {
      // Update the unused background color based on the current state
      document.documentElement.style.setProperty('--unused-background-color', prevDarkMode ? 'black' : 'white');
      return initialDarkMode;
    });
    document.documentElement.style.setProperty('--border-color', initialDarkMode ? 'white' : 'black');
    document.documentElement.style.setProperty('--background-color', initialDarkMode ? 'white' : 'black');
    document.documentElement.style.setProperty('--icon-color', initialDarkMode ? 'black' : 'white');
    document.body.style.backgroundColor = initialDarkMode ? 'black' : 'white';
    // Clean up the style when the component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []); // Empty dependency array means this effect runs once on mount


  function shuffleArray(arr: string[], seed: number): string[] {
    const getMonth = (): number => {const now = new Date(); return now.getUTCMonth();};
    const shuffledArray = [...arr];
  
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor((seed * seed + getMonth() * getMonth() * getMonth() + i + 1) % (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
  }

const fn = (
  order: number[],
  active = false,
  originalIndex = 0,
  curIndex = 0,
  x = 0,
  y = 0
) => (index: number) =>
  active && index === originalIndex
    ? {
        x: Math.max(
          Math.min(order.indexOf(index) % 4 * pixle_numX + 75, order.indexOf(index) % 4 * pixle_numX + x),
          order.indexOf(index) % 4 * pixle_numX - 75
        )+ (window.innerWidth/2 - 175),
        y: Math.max(
          Math.min(
            Math.floor(order.indexOf(index) / 4) * pixle_numY + 75,
            Math.floor(order.indexOf(index) / 4) * pixle_numY + y
          ),
          Math.floor(order.indexOf(index) / 4) * pixle_numY - 75
        ),
        scale: 1.1,
        zIndex: 1,
        shadow: 15,
        immediate: (key: string) => key === 'zIndex',
        config: (key: string) => (key === 'y' ? config.stiff : config.default),
      }
    : {
        x: order.indexOf(index) % 4 * pixle_numX + (window.innerWidth/2 - 175),
        y: Math.floor(order.indexOf(index) / 4) * pixle_numY,
        scale: 1,
        zIndex: 0,
        shadow: 1,
        immediate: false,
      };


function CheckMove(curOrder: any, start: any, end: any, x: any, y: any, winner: any, cheated: any) {
  const row = Math.floor(start / 4);
  const column = start % 4;
  const max_end = 45;
  if (!winner && !cheated) {
    if (Math.abs(x) > Math.abs(y)) {
      if (column !== 0 && x < -max_end) {
        return move(curOrder, start, start - 1);
      } else if (column !== 3 && x > max_end) {
        return move(curOrder, start, start + 1);
      } else return curOrder;
    } else if (Math.abs(x) < Math.abs(y)) {
      if (row !== 0 && y < -max_end) {
        curOrder = move(curOrder, start, start - 4);
        return move(curOrder, start - 3, start);
      } else if (row !== 2 && y > max_end) {
        curOrder = move(curOrder, start, start + 4);
        return move(curOrder, start + 3, start);
      } else return curOrder;
    } else return curOrder;
  } else return curOrder;
}

const [initialBestScore, setinitialBestScore] = useState(localStorage.getItem('bestScore'));

useEffect(() => {
  const storedUtcDay = localStorage.getItem('utcDay');
  const currentUtcDay = new Date().getUTCDay();
  if (storedUtcDay && +storedUtcDay !== currentUtcDay) {
    // Reset bestScore to zero if UTC day changes
    localStorage.setItem('utcDay', currentUtcDay.toString());
    localStorage.removeItem('bestScore')
    setinitialBestScore(null)
  }
}, []);

function DraggableList({ items }: { items: string[] }) {
  const [backgroundWin, setBackgroundWin] = useState(false);
  const [moves, setMoves] = useState(0);
  const [cheated, setCheated] = useState(false);
  const storedBestScore = initialBestScore;
  const [bestScore, setBestScore] = useState<number | null>(
    storedBestScore !== null ? +storedBestScore : null
  );
  const [showSolveButton, setShowSolveButton] = useState(false);
  const [puzzleSolved, setPuzzleSolved] = useState(false);

  const startArray = shuffleArray(tempLetterArray, currentDay);
  //const startArray = shuffleArray('W O R D'.split(' '), currentDay)
  items = startArray;
  const order = useRef(items.map((_, index) => index));
  const [springs, api] = useSprings(items.length, fn(order.current));
  const bind = useDrag(({ args: [originalIndex], active, movement: [x, y] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = _.clamp(
      Math.round((Math.floor(curIndex / 4) * 100 + y) / 100),
      curIndex % 4,
      items.length - 1
    );

    const newOrder = CheckMove(order.current, curIndex, curRow, x, y, backgroundWin, cheated);
    if (!backgroundWin && !cheated) {
      api.start(fn(newOrder, active, originalIndex, curIndex, x, y));
    }
    if (!active) {
      if (order.current !== newOrder) increaseMoves();
      order.current = newOrder;
      const word_list = words;
      checkWin(newOrder, word_list);
    }
    console.log(moves);
  });

  function increaseMoves() {
    setMoves(moves + 1);
    
    if (moves + 1 > 19) {
      setShowSolveButton(true);
    
    }
  }

  function checkWin(curOrder: any, word_list: any) {
    const tempArray = new Array(12);
    for (let i = 0; i < 12; i++) {
      var tempnum = curOrder[i];
      tempArray[i] = items[tempnum];
    }

    var word1 = false;
    var word2 = false;
    var word3 = false;
    var array1 = tempArray[0].concat(tempArray[1].concat(tempArray[2].concat(tempArray[3])));
    var array2 = tempArray[4].concat(tempArray[5].concat(tempArray[6].concat(tempArray[7])));
    var array3 = tempArray[8].concat(tempArray[9].concat(tempArray[10].concat(tempArray[11])));

    if (array1 === word_list[0] || array1 === word_list[1] || array1 === word_list[2]) {
      word1 = true;
    }
    if (array2 === word_list[0] || array2 === word_list[1] || array2 === word_list[2]) {
      word2 = true;
    }
    if (array3 === word_list[0] || array3 === word_list[1] || array3 === word_list[2]) {
      word3 = true;
    }

    if (word1 && word2 && word3) {
    
      setBackgroundWin(true);
      setPuzzleSolved(true); // Set puzzleSolved to true when the puzzle is solved
      ReactGA.event({category: 'Finished', action: 'Completed',  label: (moves+1).toString()})
      if (bestScore === null || moves < bestScore) {
        setBestScore(moves + 1);
        localStorage.setItem('bestScore', (moves + 1).toString());
      }
    } else {
      setBackgroundWin(false);
    }
  }

  //Modal.setAppElement('#yourAppElement');
  const userVisited = localStorage.getItem('hasVisited'); //determines if the user has visited before
  const [initialVisit, setInitialVisit] = React.useState(userVisited === null);

  // Update localStorage only if it's the initial visit
  React.useEffect(() => {
    if (initialVisit) { //tells if this is the users first time, if it is show them the rules
      localStorage.setItem('hasVisited', 'true');
      setInitialVisit(false);
    }
  }, [initialVisit]);

  let subtitle:any;
  let subtitle2:any;
  const [modalIsOpen, setIsOpen] = useState(initialVisit); //will only show the users the rules if their cache shows the havent been here. 
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  //console.log('User visited before:', userVisited);
  
  function openModal() {
    setIsOpen(true);
  }
  function openModal2() {
    setIsOpen2(true);
  }
  function afterOpenModal() { // change this to move color the modal screen
    // references are now sync'd and can be accessed.
    subtitle.style.color = darkMode ? 'white' : 'black';
    
  }
  function afterOpenModal2() { // change this to move color the modal screen
    // references are now sync'd and can be accessed.
    subtitle2.style.color = darkMode ? 'white' : 'black';
    
  }
  function closeModal() {
    setIsOpen(false);
  }
  function closeModal2() {
    setIsOpen2(false);
  }



  const resetBackgroundWin = () => {
    setBackgroundWin(false);
    setMoves(0);
    setCheated(false);
    setShowSolveButton(false);
    setPuzzleSolved(false); // Reset puzzleSolved when the puzzle is reset
    // Shuffle and reset the order of the tiles
    const startArray = shuffleArray(tempLetterArray, currentDay);
    order.current = startArray.map((_, index) => index);
    api.start(fn(order.current));
  };

  const shareScore = () => {
    const shareText = `I beat today's Wordski in ${moves} moves! Can you beat my score?`;
    

    if (navigator.share) {
      navigator
        .share({
          title: 'Wordski Score',
          text: shareText,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert(`Share this link to challenge your friends: ${shareUrl}`);
    }
  };

  function solvePuzzle () {
    //const letterArray = startArray.join('').split('');
    var localStartArray = startArray;
    var solvedArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //code to unravel  solving
    for (var i = 0; i < 12; i++){
      var curLetter = tempLetterArray[i];

      var letterIndex = localStartArray.indexOf(curLetter)

      localStartArray[letterIndex] = '1'; 
      solvedArray[i] = letterIndex


    }
    api.start(fn(solvedArray));
    setCheated(true);
    setBackgroundWin(true);
    setPuzzleSolved(true); // Set puzzleSolved to true when the puzzle is solved
  }

  function ButtonDisplayed() {
    if (showSolveButton && !backgroundWin && !puzzleSolved) {
      return (
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              solvePuzzle();
              ReactGA.event({ category: 'Button', action: 'Solve for Me', label: 'Solve for Me' });
            }}
            className={styles.solveButton}
          >
            Solve for Me <FaMagic className={styles.icon} style={{ marginLeft: '5px' }} />
          </button>
        </div>
      );
    }
  
    if ((backgroundWin || puzzleSolved) && !cheated) {
      return (
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              resetBackgroundWin();
              ReactGA.event({ category: 'Button', action: 'Try Again', label: 'Try Again' });
            }}
            className={styles.resetButton}
          >
            Try Again <FaRedo className={styles.icon} style={{ marginLeft: '5px' }} />
          </button>
          <button
            onClick={() => {
              shareScore();
              ReactGA.event({ category: 'Button', action: 'Share', label: 'Share' });
            }}
            className={styles.shareButton}
          >
            Share <FaShare className={styles.icon} style={{ marginLeft: '5px' }} />
          </button>
        </div>
      );
    }
  
    if ((backgroundWin || puzzleSolved) && cheated) {
      return (
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              resetBackgroundWin();
              ReactGA.event({ category: 'Button', action: 'Try Again', label: 'Try Again' });
            }}
            className={styles.resetButton}
          >
            Try Again <FaRedo className={styles.icon} style={{ marginLeft: '5px' }} />
          </button>
        </div>
      );
    } else return <br />;
  }

  const videoSource = darkMode ? 'dark_mode_loop.mp4' : 'light_mode_loop.mp4';
  const winSource = darkMode ? 'word_solved_dark.PNG' : 'word_solved_light.PNG';

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      <button onClick={openModal} className={styles.questionButton}>
        <FaQuestionCircle className={styles.questionIcon} />
      </button>
      <button onClick={openModal2} className={styles.cogButton}>
        <FaCog className={styles.cogIcon} />
      </button>
{/* //-------------------------------------------------------------------------------------Modal for Game Rules------------------------------------------------------------------------------------------ */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)' },
          content: {width: '70%', maxWidth: '400px', maxHeight: '395px', margin: 'auto', background: darkMode ? '#333' : '#fff', color: darkMode ? 'white' : 'black', padding: '20px'},
        }}
        contentLabel="Game Rules"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginTop: '0px', background: darkMode ? '#333' : '#fff', color: darkMode ? 'white' : 'black', fontWeight: 'bold' }}>
          <button onClick={closeModal} className={styles.closeButton}>
            &times;
          </button>
        </div>
        <h2 ref={(_subtitle) => (subtitle = _subtitle)} className={`${styles.title}`} style={{ textAlign: 'center', marginTop: '-5px'}} // Center the text
        >
        How To Play
        </h2>
        <div style={{ marginBottom: '10px', fontFamily: 'Quicksand', fontWeight: 'bold', touchAction: 'none', userSelect: 'none' }}>Rearrange the tiles to form three valid four-letter words within the rows related to the theme.</div>
        <div style={{ marginBottom: '5px', fontFamily: 'Quicksand', fontWeight: 'bold', touchAction: 'none', userSelect: 'none' }}>1. Drag and drop adjacent tiles to swap their positions.</div>
        <video
          autoPlay // Add autoPlay attribute for autoplay
          loop // Add loop attribute for continuous playback
          muted // Add muted attribute to allow autoplay on most browsers
          playsInline
          controls={false}
          style={{ width: '25%', maxWidth: '200px', margin: 'auto', borderRadius: '8px', height: 'auto', transition: 'border ease', display: 'block', paddingBottom: '4px'}}
          onError={(e) => console.error('Error loading video:', e)}
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
        <div style={{ marginBottom: '5px', fontFamily: 'Quicksand', fontWeight: 'bold', touchAction: 'none', userSelect: 'none' }}>2. Once all rows match the valid words, the puzzle is solved!</div>
        <img
          src={winSource}
          alt="GIF"
          style={{ width: '40%', maxWidth: '200px', margin: 'auto', borderRadius: '8px', height: 'auto', transition: 'border ease', display: 'block', paddingBottom: '0px', paddingTop: '10px', touchAction: 'none', userSelect: 'none' }}
          onError={(e) => console.error('Error loading GIF:', e)}
        
        />
        <div style={{ marginBottom: '0px', textAlign: 'center' }}>
        </div>
      
      </Modal>
{/* //------------------------------------------------------------------------------------------end Modal------------------------------------------------------------------------------------------- */}

{/* //-------------------------------------------------------------------------------------Modal For the Settings------------------------------------------------------------------------------------ */}
<Modal
  isOpen={modalIsOpen2}
  onAfterOpen={afterOpenModal2}
  onRequestClose={closeModal2}
  style={{
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    content: { width: '70%', maxWidth: '400px', maxHeight: '250px', margin: 'auto', background: darkMode ? '#333' : 'white', color: darkMode ? 'white' : 'black', padding: '20px' },
  }}
  contentLabel="Game Rules"
>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginTop: '0px', background: darkMode ? '#333' : '#fff', color: darkMode ? 'white' : 'black', fontWeight: 'bold' }}>
          <button onClick={closeModal2} className={styles.closeButton}>
            &times;
          </button>
        </div>
  <h2
    ref={(_subtitle2) => (subtitle2 = _subtitle2)}
    className={`${styles.title} ${darkMode ? styles.darkMode : styles.lightMode}`}
    style={{ textAlign: 'center', marginTop: '-5px' }} // Center the text
  >
    Settings
      </h2>
        <div className={`${darkMode ? styles.darkLine : styles.lightLine}`}></div>
        <div className={styles.settingsContainer}>
          <div className={styles.settingsLabel}>
            <label className={`${styles.SettingsMenu} ${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}>Toggle Dark Mode:</label>
            <input
              type="checkbox"
              id="darkModeToggle"
              className={`${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}
              checked={darkMode}
              onChange={toggleDarkMode} // Update dark mode state on checkbox change
            />
          </div>
        
          <div className={`${darkMode ? styles.darkLine : styles.lightLine}`}></div>
          <div className={styles.settingsLabel}>
            <label className={`${styles.SettingsMenu} ${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}>
              Leave a Comment?
            </label>
            <a
              href={`mailto:dailywordski@gmail.com?subject=Wordski - Leave a Comment&body=%0D%0A%0D%0A%0D%0A -- A few details for us --%0D%0AClicked on ${new Date().toLocaleString()}%0D%0ADevice: ${navigator.userAgent}`}
              className={`${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}
            >
              Contact us
            </a>
          </div>
          <div className={`${darkMode ? styles.darkLine : styles.lightLine}`}></div>
          <div className={styles.settingsLabel}>
            <label className={`${styles.SettingsMenu} ${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}>
              Report a Bug?
            </label>
            <a
              href={`mailto:dailywordski@gmail.com?subject=Wordski - Report a Bug&body=%0D%0A%0D%0A%0D%0A -- A few details for us --%0D%0AClicked on ${new Date().toLocaleString()}%0D%0ADevice: ${navigator.userAgent}`}
              className={`${darkMode ? styles.darkmodetextlink : styles.lightmodetextlink}`}
            >
              Contact us
            </a>
          </div>
          <div className={`${darkMode ? styles.darkLine : styles.lightLine}`}></div>
        </div>
      </Modal>
{/* //------------------------------------------------------------------------------------------end Modal------------------------------------------------------------------------------------------- */}


      <div className={`${styles.content} ${darkMode ? styles.darkMode : styles.lightMode}`}>
      {springs.map(({ zIndex, shadow, x, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          className={styles.animatedDiv}
          style={{zIndex, boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.25) 0px ${s}px ${2 * s}px 0px`), x, y, scale, backgroundColor: (backgroundWin || cheated) ? 'lightgreen' : 'lightgrey',}}
        >
          {items[i]}
        </animated.div>
      ))}
      <h3 className={styles.movesContainer}>
        {cheated ? 'Solved for Me' : ['Moves: ', moves]}
      </h3>
      <h3 className={styles.bestScoreContainer}>
        Best Score: {bestScore}
      </h3>
    </div>
    <ButtonDisplayed/>
    </div >
  );


}

return (
  <div
    style={{
      background: darkMode ? 'black' : 'white',
      color: darkMode ? 'white' : 'black',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    }}
  >
    <div style={{ marginBottom: '-40px', paddingTop: '15px', paddingLeft: '10px', fontFamily: 'Quicksand', fontWeight: 'bold', touchAction: 'none', userSelect: 'none', fontSize: '0.8em' }}>
      {/* The countdown will be updated dynamically in the HTML file */}
      Next Wordski: <span id="countdown"></span>
    </div>
    <h1 style={{ borderBottom: `3px double ${darkMode ? '#eee' : 'black'}`, width: '100%', paddingBottom: '30px', textAlign: 'center', fontSize: '4.25em', touchAction: 'none', userSelect: 'none' }}>
      Wordski
    </h1>
    {/* Add the double line right underneath the title */}
    <h2 style={{ marginTop: '-30px', marginBottom: '0px', textAlign: 'center', touchAction: 'none', userSelect: 'none' }}>Theme: {theme}</h2>
    <DraggableList items={'B O A T S A I L S A N D'.split(' ')} />
    <div style={{ borderTop: `3px double ${darkMode ? '#eee' : 'black'}`, width: '100%', paddingTop: '5px', background: darkMode ? 'black' : 'white', color: darkMode ? 'white' : 'black' }}></div>
  </div>
);
};